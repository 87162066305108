var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal_design" }, [
    _c(
      "div",
      {
        staticClass: "modal_wrap",
        style: _vm.meetingLoader ? "min-height: 40vh" : null,
      },
      [
        _c("div", { staticClass: "close-btn" }, [
          _c("div", [
            _c("img", {
              staticStyle: { float: "right", cursor: "pointer" },
              attrs: { src: "/img/onboarding/cancel_close.svg" },
              on: { click: _vm.closeModal },
            }),
          ]),
        ]),
        _vm.meetingLoader
          ? _c(
              "div",
              { staticClass: "spinnerBtn" },
              [
                _c("v-progress-circular", {
                  attrs: { size: 50, color: "black", indeterminate: "" },
                }),
              ],
              1
            )
          : _c("div", { staticClass: "success-div" }, [
              _vm._m(0),
              _vm._m(1),
              _c("div", { staticClass: "flex-div" }, [
                _c("div", { staticClass: "meeting-details" }, [
                  _vm._v(
                    "\n          Meeting scheduled for\n          " +
                      _vm._s(
                        _vm
                          .$moment(_vm.meetingPayload.meeting_start_date)
                          .format("dddd, MMMM Do YYYY, h:mm:ss a")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrap-container" }, [
      _c("div", { staticClass: "header-text" }, [_vm._v("Awesome!")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrap" }, [
      _c("img", { attrs: { src: "/img/onboarding/mark-success.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }