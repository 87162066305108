import Axios from "axios";

const {
  VUE_APP_CALENDLY_TOKEN: accessToken,
  VUE_APP_CALENDLY_EXPERT_TOKEN: expertAccessToken,
  VUE_APP_CALENDLY_FOUNDER_TOKEN: founderAccessToken,
} = process.env;

export const calendlyEndpoint = (url, type) => {
  const header = {
    headers: {
      Authorization: `Bearer ${
        type === "expert"
          ? expertAccessToken
          : type === "founder"
          ? founderAccessToken
          : accessToken
      }`,
    },
  };
  return Axios.get(url, header);
};
