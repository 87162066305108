<template>
  <div class="modal_design">
    <div class="modal_wrap" :style="meetingLoader ? 'min-height: 40vh' : null">
      <div class="close-btn">
        <div>
          <img
            @click="closeModal"
            src="/img/onboarding/cancel_close.svg"
            style="float: right; cursor: pointer"
          />
        </div>
      </div>
      <div v-if="meetingLoader" class="spinnerBtn">
        <v-progress-circular
          :size="50"
          color="black"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="success-div">
        <div class="wrap-container">
          <div class="header-text">Awesome!</div>
        </div>
        <div class="image-wrap">
          <img src="/img/onboarding/mark-success.svg" />
        </div>
        <div class="flex-div">
          <div class="meeting-details">
            Meeting scheduled for
            {{
              $moment(meetingPayload.meeting_start_date).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meetingLoader: {
      type: Boolean,
    },
    meetingPayload: {
      type: Object,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data: () => ({}),
  created() {},
  mounted() {},
  methods: {
    // closeModal() {
    //   window.location.reload();
    // },
  },
};
</script>

<style scoped>
.modal_design {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_wrap {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  width: 516px;
  height: 425px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.spinnerBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.wrap-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-top: 70px;
}
.header-text {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  text-align: center;
}
.image-wrap {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
.meeting-details {
  margin-top: 32px;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #1b1e22;
  width: 334px;
}
.flex-div {
  display: flex;
  justify-content: center;
}
.close-btn {
  padding-right: 24px;
  padding-top: 16px;
}
</style>

